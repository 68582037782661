import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionLineDescription,
  SectionPageTitle,
} from "../components/Section";
import ProductCarousel from "../components/ProductCarousel";

const manufacturesPageTemplate = ({ location, data }) => (
  <Layout location={location}>
    <Seo
      title={data.contentfulManufacturesPages.metaTitle}
      description={data.contentfulManufacturesPages.metaDescription}
    />
    <Section
      ept="160px"
      epb="60px"
      xpt="140px"
      xpb="40px"
      pt="120px"
      pb="30px"
      bgColor="#F8FAFB"
      className="section-manufacturer"
    >
      <SectionLineDescription mb="0" textAlign="center">
        <Container>
          <SectionPageTitle textAlign="center">
            {data.contentfulManufacturesPages.pageTitle}
          </SectionPageTitle>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulManufacturesPages.content.childMarkdownRemark
                  .html,
            }}
          />
        </Container>
      </SectionLineDescription>
    </Section>
    <ProductCarousel data={data.allContentfulProduct.edges} />
  </Layout>
);

export default manufacturesPageTemplate;

export const pageQuery = graphql`
  query manufacturesPageTemplateQuery($id: String!) {
    contentfulManufacturesPages(id: { eq: $id }) {
      metaTitle
      metaDescription
      pageTitle
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
